<template>
  <v-dialog :value="true" @click:outside="close()" scrollable max-width="1000">
    <v-card>
      <v-toolbar color="info" dark class="mb-4"
        ><v-toolbar-title>myGymer-Wahl</v-toolbar-title>

        <v-spacer />
        <v-icon @click="close()">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text>
        <router-view></router-view>
      </v-card-text>
      <v-divider />
      <v-card-actions
        ><v-spacer /><v-btn @click="close()" text
          >schliessen</v-btn
        ></v-card-actions
      ></v-card
    ></v-dialog
  >
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  methods: {
    close() {
      this.$router.push({ name: "OptionalSchoolClass" });
    },
  },
});
</script>
